import React from "react"

import Layout from "components/Layout/en"
import Product from "components/Layout/Product/index"
import productImg from "./product.png"
import productIcon from "./MoonvisionToolbox.svg"
import { Table } from "react-bootstrap"
import { MdDone } from "react-icons/md"
import { FormattedMessage } from "react-intl"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        The MoonVision Toolbox is the most efficient tool to create models for
        automated visual quality inspection. It supports you right from the
        first image and ensures that you stay in control over the recognition in
        operation.
      </p>
      <p>
        To get a better understanding for the capabilities of our computer
        vision platform we now provide a limited functionality set for free.
      </p>
      <a href="https://app.moonvision.io/signup" className="btn btn-primary">
        Try for free
      </a>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="MoonVision Toolbox"
        title="The Computer Vision Toolbox for Domain Experts"
        subtitle="The most powerful toolset on the market."
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Learning System",
          "Simple Installation",
          "Hardware agnostic",
        ]}
        technicalTable={
          <Table>
            <thead>
              <tr>
                <th colSpan="2">
                  <FormattedMessage id="sliderBenefits" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="sliderHeading1" /> -{" "}
                  <FormattedMessage id="sliderText1" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="sliderHeading2" /> -{" "}
                  <FormattedMessage id="sliderText2" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="sliderText3" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="sliderText4" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="sliderText5" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="toolBoxVideo" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="toolBoxManufacturing" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="toolBoxAdaptation" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <h3>Groundbreaking results with only a few images</h3>
          <p>
            Our training pipeline delivers amazing results with only 11 Images
            (like in this example). Small Datasets make constant learning and
            internal expert usage possible.
          </p>
          <h3>Simple Realtime Data Management</h3>
          <p>
            Dataset management can be hard as datasets grow and more people need
            to view and edit them. We keep everything in one place so that you
            and your team can work in parallel and focus on making the highest
            quality dataset. Our realtime views update as you label and provide
            an immediate glimpse into your data.
          </p>
          <h3>Next Level Annotation</h3>
          <p>
            Not every dataset annotation can be outsourced when domain knowledge
            is required. We take a completely new approach by only requiring a
            few labelled images as training data to our computer vision models.
            We use Transfer Learning, Few Shot detection and special training
            procedures to enable great results with very little of your domain
            expert's time.
          </p>
          <h3>Automated Label Generation</h3>
          <p>
            Generate Auto Entities for Objects and Segmentation based on
            pre-trained networks to speed up the Labelling Process.
          </p>
          <h3>Single Image Recommendation System</h3>
          <p>
            Annotation is a hustle - we have the best label recommendation on
            the market in 2019. Its Accuracy of 90+ Percent already kick in with
            one image labeled. Fastest Labelling speed guaranteed.
          </p>
          <h3>Scene Detection and Clustering</h3>
          <p>
            Data generation with Video is cheap and simple. Extracting the
            moments that matter is hard. The Toolbox introduces a new and
            massive efficient way of data extraction.
          </p>
          <h3>Collaborate with your team</h3>
          <p>
            Invite your colleagues and create teams, allowing you to manage data
            together. Make sure your fellow labellers focus only on the
            important tasks. Get advanced analytics, such as how much is left to
            label.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
